<script>


import { 
  ArrowUpIcon,
  ArrowRightIcon,
  StarIcon
   } from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

import VueCookies from "vue-cookies";
import Vue from "vue";

Vue.use(VueCookies);

export default {
  data() {
    return {
      newsData: [
        {thumbnail : null,
        title : null,
        contents : null,
        date : null,
        link: null},
        {thumbnail : null,
        title : null,
        contents : null,
        date : null,
        link: null},
        {thumbnail : null,
        title : null,
        contents : null,
        date : null,
        link: null},
      ],
    };
  },
  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
    ArrowRightIcon,
    StarIcon,
  },
  methods: {
    logClicked () {
      console.log('Clicked')
    },
    doTodayClose() {
        this.$cookies.set('popup1', true, '1d');
        this.$refs['popup1'].hide();
    },
    doClose() {
      this.$refs['popup1'].hide()
    },
  },
  async mounted() {

    const cookie = this.$cookies.get('popup1');
    if (!cookie) {
      this.$refs.modal.click();
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }   

    const news = await this.$flamelinkApp.content.get({ 
        schemaKey: 'news' ,
        fields: ['title', 'contents', '_fl_meta_.createdDate', 'thumbnail', 'link'],
        orderBy: { field: '_fl_meta_.createdDate', order: 'desc' },
        limit: 3,
        populate: ["thumbnail"]
      })

    const newsKeys = Object.keys(news);
    this.newsData.length = 0;

    newsKeys.forEach(each => {
        this.newsData.push(
            {
                thumbnail : news[each].thumbnail[0].url,
                title : news[each].title,
                contents : news[each].contents,
                date : formatDate(news[each]._fl_meta_.createdDate.toDate()),
                link : news[each].link,
            }
        );
    });
  }
};
</script>

<template>
  <div>
    <!-- <Navbar :is-white-navbar="true" /> -->
    <Navbar :nav-light="true" />
    <!-- Hero Start -->
    <section id="home" class="bg-half-170 d-table w-100" style="background: url('images/hero_2000.jpeg') center center / cover no-repeat;">
        <div class="container">
            <div class="bg-trans" style="text-align: center;">
                <h4 class="text-white float-left" style="font-family: 'Noto Sans KR', sans-serif; margin-top: 30px;">
                    <span class="float-left" style="font-size: 2.8vmax; font-weight: bold; text-align: left; text-shadow: 2px 2px 15px black;"> 
                        {{ $t("message.hero-title") }}
                    </span>
                    <br>
                    <span class="float-left" style="font-size: 1.4vmax; font-weight: normal; color: white /*rgb(51, 165, 255)*/; text-align: left; text-shadow: 1px 1px 11px black;">
                        {{ $t("message.hero-sub-title") }}
                    </span>
                </h4>
            </div>
            <div class="container mt-5" style="height: 20vmax;">
            </div>
        </div>
    </section>
    <!-- <section
      class="bg-half-170 d-table w-100"
      style="background: url('images/hero_2000.jpeg') center bottom / cover no-repeat;"
      id="home"
    >
        <div class="container"><div class="bg-trans" style="text-align: center;">
            <h4 class="text-white float-center" style="font-family: GmarketSans;">
                <span class="float-center" style="font-size: 3.2vmax; font-weight: bold;">
                    {{ $t("message.hero-title") }}
                </span>
                <br>
                <span class="float-center" style="font-size: 2.4vmax; font-weight: bold; text-decoration: underline 0.5vmax orange; text-underline-offset: 0px;">
                    {{ $t("message.hero-sub-title") }}
                </span>
            </h4>
            <h1 class="text-white float-center" style="font-family: GmarketSans;">
                <span style="font-size: 2.1vmax; font-weight: bolder;">
                    {{ $t("message.hero-message") }}
                </span>
            </h1>
        </div> -->
        <!-- <div class="container mt-5" style="height: 20vmax;"> -->
            <!--<div class="rounded bg-white shadow" style="position: relative; width: auto; height: auto; left: 50%; transform: translateX(-50%); text-align: center; display: flex; align-items: center; padding: 10px 5px 10px 5px;">
                <div style="width: 100%">
                    <a
                    href="https://firebasestorage.googleapis.com/v0/b/landbox-landingcms.appspot.com/o/flamelink%2Fmedia%2Flandbox_whitepaper_en_19_compressed.pdf?alt=media&token=8f2735d3-15cd-4f74-ac93-5710aa506652"
                    class="btn btn-primary" target="_blank"
                    style="width: auto; margin: 7.5px;"
                    ><i class="mdi mdi-book-outline"></i> Whitepaper (EN)</a
                    >
                    <a
                    href="https://firebasestorage.googleapis.com/v0/b/landbox-landingcms.appspot.com/o/flamelink%2Fmedia%2Flandbox_whitepaper_ko_19_compressed.pdf?alt=media&token=40cb558c-c562-47be-9a02-917fd9347d51"
                    class="btn btn-outline-primary" target="_blank"
                    style="width: auto; margin: 7.5px;"
                    ><i class="mdi mdi-book-outline"></i>
                    백서 (한글)</a
                    >
                </div>
            </div>-->
        <!-- </div> -->
      <!-- </div> -->
      <!--end container-->
    <!-- </section> -->
    <!--end section-->
    <!-- Hero End -->
<!-- <div id="xi-ticker"></div> -->
<div>
<coingecko-coin-price-marquee-widget  coin-ids="new-landbox,bitcoin,ethereum" currency="usd" background-color="#ffffff" locale="en"></coingecko-coin-price-marquee-widget>
</div>

    <a v-show="false"
        href="javascript:void(0)"
        v-b-modal.modal
        data-toggle="modal"
        data-target="#modal"
        
        ref="modal" 
        @click="logClicked"
        
    >
        modal</a
    >

    <!-- <b-modal id="modal" ref="popup1" centered :hide-header="true" :hide-footer="true" body-class="p-0">
      <div class="position-relative">
        <img 
            src="images/220523_popup_01.jpg"
            class="img-fluid"
        />
        <div class="position-absolute"
        style="width: 90%; bottom: 30px; left: 50%; transform: translateX(-50%);">
            <a
              href="https://meta.landbox.io/"
              target="_blank"
              class="btn btn-warning rounded-pill p-3"
              style="width: 100%; font-size: 1.5rem"
              >프로젝트 참여하기</a
            >
        </div>
      </div>
      <div size="sm" class="btn float-left bg-transparent" @click="doTodayClose">오늘 하루 보지 않기</div>
      <div size="sm" class="btn float-right bg-transparent" @click="doClose">닫기</div>
    </b-modal> -->

    <!-- Partners start -->
    <section class="py-4 bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <a href="https://www.gopax.co.kr/exchange/land-krw" target="_blank">
                <img
                src="images/gopax.png"
                class="avatar avatar-ex-sm"
                alt=""
                />
            </a>
            
          </div>
          <!--end col -->

           <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <a href="https://global.bittrex.com/Market/Index?MarketName=USDT-LAND" target="_blank">
                <img
                src="images/Logo-Color-Dark@4x.png"
                class="avatar avatar-ex-sm"
                alt=""
                />
            </a>
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <a href="https://www.probit.kr/app/exchange/LAND-KRW" target="_blank">
                <img
                src="images/probit.png"
                class="avatar avatar-ex-sm"
                alt=""
                />
            </a>
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <a href="https://xangle.io/project/LAND/key-info" target="_blank">
                <img
                src="images/symbol-xangle-04.png"
                class="avatar avatar-ex-sm"
                alt=""
                />
            </a>
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <a href="https://coinmarketcap.com/currencies/landbox/" target="_blank">
                <img
                src="images/coinmarketcap_1.svg"
                class="avatar avatar-ex-sm"
                alt=""
                />
            </a>
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <a href="https://www.coingecko.com/coins/landbox" target="_blank">
                <img
                src="https://static.coingecko.com/s/coingecko-logo-d13d6bcceddbb003f146b33c2f7e8193d72b93bb343d38e392897c3df3e78bdd.png"
                class="avatar avatar-ex-sm"
                alt=""
                />
            </a>
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <a href="https://info.uniswap.org/#/tokens/0x0e2ef8aecb3c01ad5d596f1b67134e178199984d" target="_blank">
                <img
                src="images/Uniswap_Lockup_Large_Black.svg"
                class="avatar avatar-ex-sm"
                alt=""
                />
            </a>
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x557f20ce25b41640ade4a3085d42d7e626d7965a" target="_blank">
                <img
                src="images/pancakeswap.png"
                class="avatar avatar-ex-sm"
                alt=""
                />
            </a>
          </div>
          <!--end col--> 
          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <a href="https://www.certik.org/projects/landbox" target="_blank">
                <img
                src="images/1_H7D1_Ofwjmso6QwOIg5M9A@2x.png"
                class="avatar avatar-ex-sm"
                alt=""
                />
            </a>
          </div>

        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Partners End -->

<!--recent post-->
<section>
    <div class="container mt-100 mt-60">
        <div class="row align-items-center mb-4 pb-4">
            <div class="col-md-8">
                <div class="section-title text-md-left">
                    <h4 class="mb-4">{{ $t("message.news1") }}
                     </h4>
                    <p class="text-muted mb-2 para-desc"><span class="text-primary font-weight-bold">{{ $t("message.landbox") }}</span>{{ $t("message.news2") }}
                    </p>
                
                </div>
            </div>
            <!--end col-->

<div class="col-md-4 mt-4 mt-sm-0 text-md-right">
        <a href="https://medium.com/landbox-news" class="btn btn-outline-primary" target="_blank">{{ $t("message.seemore") }}<arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
    <!-- <coingecko-coin-ticker-widget  coin-id="landbox" currency="usd" locale="en" width="0"></coingecko-coin-ticker-widget> -->
    
</div>
            <!--end col-->
            
            
        </div>
        <!--end row this.$newsData[0].title-->

        <div class="row">
            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card blog rounded border-0 shadow overflow-hidden">
                    <div class="position-relative">
                        <img :src="this.newsData[0].thumbnail" class="card-img-top" alt="...">
                        <div class="overlay rounded-top bg-dark"></div>
                    </div>
                    <div class="card-body content">
                        <h5><a href="javascript:void(0)" class="card-title title text-dark">{{ newsData[0].title }}</a></h5>
                        <div class="post-meta d-flex justify-content-between mt-3">
                            <a :href="this.newsData[0].link" target="_blank" class="text-muted readmore">{{ $t("message.readmore") }}<i class="mdi mdi-chevron-right"></i></a>
                        </div>
                    </div>
                    <div class="author">
                        <small class="text-light date"><i class="mdi mdi-calendar-check"></i> {{ newsData[0].date }} </small>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card blog rounded border-0 shadow overflow-hidden">
                    <div class="position-relative">
                        <img :src="this.newsData[1].thumbnail" class="card-img-top" alt="...">
                        <div class="overlay rounded-top bg-dark"></div>
                    </div>
                    <div class="card-body content">
                        <h5><a href="javascript:void(0)" class="card-title title text-dark">{{ newsData[1].title }}</a></h5>
                        <div class="post-meta d-flex justify-content-between mt-3">
                            <a :href="this.newsData[1].link" target="_blank" class="text-muted readmore">{{ $t("message.readmore") }}<i class="mdi mdi-chevron-right"></i></a>
                        </div>
                    </div>
                    <div class="author">
                        <small class="text-light date"><i class="mdi mdi-calendar-check"></i> {{ newsData[1].date }} </small>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card blog rounded border-0 shadow overflow-hidden">
                    <div class="position-relative">
                        <img :src="this.newsData[2].thumbnail" class="card-img-top" alt="...">
                        <div class="overlay rounded-top bg-dark"></div>
                    </div>
                    <div class="card-body content">
                        <h5><a href="javascript:void(0)" class="card-title title text-dark">{{ newsData[2].title }}</a></h5>
                        <div class="post-meta d-flex justify-content-between mt-3">
                            <a :href="this.newsData[2].link" target="_blank" class="text-muted readmore">{{ $t("message.readmore") }}<i class="mdi mdi-chevron-right"></i></a>
                        </div>
                    </div>
                    <div class="author">
                        <small class="text-light date"><i class="mdi mdi-calendar-check"></i> {{ newsData[2].date }} </small>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--recent post end-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6">
            <img src="images/landinv.jpg" class="img-fluid" alt="" />
          </div>
          <!--end col-->

          <div class="col-lg-7 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
            <div class="ml-lg-5">
              <div class="section-title mb-3">
                <span class="badge badge-pill badge-soft-primary"
                  >{{ $t("message.landmain2") }}</span
                >
                <h4 class="title my-3">{{ $t("message.landmain1") }}</h4>
                <p class="text-muted para-desc">
                  {{ $t("message.landmain3") }}
                  <span class="text-primary font-weight-bold">{{ $t("message.landmain9") }}</span>
                  {{ $t("message.landmain4") }}
                </p>
                <p class="text-muted para-desc mb-0">
                  {{ $t("message.landmain5") }}
                </p>
              </div>

              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >{{ $t("message.landmain6") }}
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >{{ $t("message.landmain7") }}
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >{{ $t("message.landmain8") }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->

<!--PROJECts STARTS-->
<section class="section overflow-hidden">
  <div class="container mt-100 mt-60">
        <div class="row align-items-center mb-4 pb-4">
            <div class="col-md-12">
                <div class="section-title text-md-left">
                    <h4 class="mb-4">{{ $t("message.projects") }}</h4>
                    <p class="text-muted mb-0"><span class="text-primary font-weight-bold">{{ $t("message.landbox") }}</span>{{ $t("message.project1") }}</p>
                </div>
            </div>
            <!--end col-->

        </div>
        <!--end row-->


    <div class="row align-items-center">
        <div class="col-lg-6 col-md-5">
            <div class="app-feature-shape-left position-relative">
                <div class="text-center text-md-left">
                    <img src="images/aok_mockup@2x.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
        <!--end col-->
 
        <div class="col-lg-6 col-md-7 mt-5 mt-sm-0">
            <div class="section-title">
                <h1 class="title mb-3">{{ $t("message.projects2") }}<br>{{ $t("message.projects3") }}<span class="text-primary font-weight-bold">{{ $t("message.projects4") }}</span></h1>
                <p class="para-desc text-muted">{{ $t("message.project5") }}</p>
                <ul class="list-unstyled text-muted">
                    <li class="mb-0"><span class="text-primary h5 "><i class="uil uil-check-circle align-middle"></i></span>{{ $t("message.project6") }}</li>
                    <li class="mb-0"><span class="text-primary h5 "><i class="uil uil-check-circle align-middle"></i></span>{{ $t("message.project7") }}</li>
                    <li class="mb-0"><span class="text-primary h5 "><i class="uil uil-check-circle align-middle"></i></span>{{ $t("message.project8") }}</li>
                </ul>
                <div class="mt-4">
                    <a href="https://www.auctionok.co.kr/" class="mt-3 h6 text-primary" target="_blank">{{ $t("message.project9") }}<i class="mdi mdi-chevron-right"></i></a>
                </div>
            </div>
        </div>
        <!--end col-->
    </div>
    <!--end row-->
  </div> 
  <!--end container-->

</section>
<!--PROJECts ENDS-->


<!-- Start Section -->
    <section >
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 col-md-7 col-12">
                    <div class="section-title mt-5">
                        <h4>{{ $t("message.faq1") }}</h4>
                    </div>

                    <div class="faq-content mt-4 pt-3">
                        <div class="accordion" id="accordionExampletwo">
                            <b-card no-body class="mb-2 border-0 rounded ">
                                <b-card-header class="border-0 bg-light p-3 pr-5">
                                    <a class="faq position-relative text-dark" v-b-toggle.accordion2-1 href="javascript: void(0);">
                                        <h6 class="title mb-0">{{ $t("message.faq2") }}</h6>
                                    </a>
                                </b-card-header>
                                <b-collapse id="accordion2-1"  accordion="accordion" role="tabpanel">
                                    <b-card-body class="px-2 py-4">
                                        <p class="text-muted mb-0 faq-ans">{{ $t("message.faq3") }}</p>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-2 border-0  rounded ">
                                <b-card-header class="border-0 bg-light p-3 pr-5">
                                    <a class="faq position-relative text-dark" v-b-toggle.accordion2-2 href="javascript: void(0);">
                                        <h6 class="title mb-0">{{ $t("message.faq4") }}</h6>
                                    </a>
                                </b-card-header>
                                <b-collapse id="accordion2-2" accordion="accordion" role="tabpanel">
                                    <b-card-body class="px-2 py-4">
                                        <p class="text-muted mb-0 faq-ans">{{ $t("message.faq5") }}</p>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-2 border-0  rounded ">
                                <b-card-header class="border-0 bg-light p-3 pr-5">
                                    <a class="faq position-relative text-dark" v-b-toggle.accordion2-3 href="javascript: void(0);">
                                        <h6 class="title mb-0">{{ $t("message.faq6") }}</h6>
                                    </a>
                                </b-card-header>
                                <b-collapse id="accordion2-3" accordion="accordion" role="tabpanel">
                                    <b-card-body class="px-2 py-4">
                                        <p class="text-muted mb-0 faq-ans">{{ $t("message.faq7") }}</p>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            
                        </div>
                    </div>

                    
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->

    </section>
    <!--end section-->
    <!-- End Section -->



<!--road map(vertical) start-->
<section class="mt-5" >
     <div class="container ">
         <div class="row align-items-center mb-4 pb-4">
             <div class="col-md-12">
                 <div class="section-title text-md-left">
                     <h4 class="mb-4">{{ $t("message.roadsub") }}</h4><!--'로드맵' 분류-->
                     <p class="text-muted mb-0">{{ $t("message.roadti") }}</p><!--'랜드박스가 걸어온 길과 앞으로의 계획' 로드맵 분류 설명-->
                 </div>
             </div>
                 <!--end col-->

         </div>
              <!--end row-->
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="main-icon rounded-pill text-primary text-center pt-2">
                    <star-icon class="fea icon-md-sm"></star-icon>
                </div>

                <div class="timeline-page pt-2 position-relative">
                    <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="duration date-label-left border rounded p-2 px-4 position-relative shadow">2019 ~ 2020</div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="card event event-description-right rounded shadow border-0 overflow-hidden float-left">
                                    <!-- <img src="images/blog/01.jpg" class="img-fluid" alt=""> -->
                                    <img src="images/logo_back_blue.png" class="img-fluid" alt="">
                                    <div class="card-body">
                                        <h5 class="mb-0 text-capitalize">
                                            {{ $t("message.roadat") }}</h5>
                                        <p class="mt-3 mb-0 text-muted">
                                        {{ $t("message.roada1") }}<br>
                                        {{ $t("message.roada2") }}<br>
                                        {{ $t("message.roada3") }}<br>
                                        {{ $t("message.roada4") }}<br>
                                        {{ $t("message.roada5") }}<br>
                                        {{ $t("message.roada6") }}
                                          <div class="container">
                                             <div class="row">
                                                 <div class="col-sm">
                                                     <img src="images/probit_purple.png" class="img-fluid" alt="" >
                                                 </div>
                                                 <div class="col-sm">
                                                     <img src="images/gopax_black.png" class="img-fluid" alt="" >
                                                 </div>
                                             </div>
                                         </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end timeline item-->

                    <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2">
                                <div class="card event event-description-left rounded shadow border-0 overflow-hidden float-left">
                                    <!-- <img src="images/blog/02.jpg" class="img-fluid" alt=""> -->
                                    <div class="card-body">
                                        <h5 class="mb-0 text-capitalize">
                                            {{ $t("message.roadbt") }}<br>
                                            {{ $t("message.roadbt1") }}</h5>
                                        <p class="mt-3 mb-0 text-muted">
                                            {{ $t("message.roadb1") }}<br>
                                            {{ $t("message.roadb2") }}<br>
                                            {{ $t("message.roadb3") }}<br>
                                            {{ $t("message.roadb4") }}<br>
                                            {{ $t("message.roadb5") }}<br>
                                            {{ $t("message.roadb6") }}</p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">
                                <div class="duration duration-right rounded border p-2 px-4 position-relative shadow text-left">2020 ~ 2021</div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end timeline item-->

                    <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="duration date-label-left border rounded p-2 px-4 position-relative shadow">2022 ~ 2023</div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="card event event-description-right rounded shadow border-0 overflow-hidden float-left">
                                    <!-- <img src="images/blog/03.jpg" class="img-fluid" alt=""> -->
                                    <img src="images/bittrex-log-freelogovectors.net_.png" class="img-fluid" alt="">
                                    <div class="card-body">
                                        <h5 class="mb-0 text-capitalize">
                                            {{ $t("message.roadct") }}</h5>
                                        <p class="mt-3 mb-0 text-muted">
                                            {{ $t("message.roadc1") }}<br>
                                            {{ $t("message.roadc2") }}<br>
                                            {{ $t("message.roadc3") }}<br>
                                            {{ $t("message.roadc4") }}<br>
                                            {{ $t("message.roadc5") }}<br>
                                            {{ $t("message.roadc6") }}</p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end timeline item-->


                 <!--   
                    <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2">
                                <div class="card event event-description-left rounded shadow border-0 overflow-hidden float-left">
                                    <img src="images/logo_back_blue.png" class="img-fluid" alt="">
                                    <div class="card-body">
                                        <h5 class="mb-0 text-capitalize">{{ $t("message.road18") }}</h5>
                                        <p class="mt-3 mb-0 text-muted">{{ $t("message.road19") }}<br>
                                        {{ $t("message.road20") }}<br>
                                        {{ $t("message.road21") }}<br>
                                        {{ $t("message.road22") }}</p>
                                    </div>
                                </div>
                            </div> -->
                            <!--end col-->

                            <!--
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">
                                <div class="duration duration-right rounded border p-2 px-4 position-relative shadow text-left">2020 2Q</div>
                            </div>
                        -->
                            <!--end col-->
                        <!--
                            </div>
                        -->
                        <!--end row-->
                    <!--
                        </div>
                    -->
                    <!--end timeline item-->

                    <!--<div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="duration date-label-left border rounded p-2 px-4 position-relative shadow">2020 3Q</div>
                            </div>
                        -->
                            <!--end col-->
                            <!--
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="card event event-description-right rounded shadow border-0 overflow-hidden float-left">
                                    <img src="images/probit_purple.png" class="img-fluid" alt="">
                                    <div class="card-body">
                                        <h5 class="mb-0 text-capitalize">{{ $t("message.road23") }}</h5>
                                        <p class="mt-3 mb-0 text-muted">{{ $t("message.road24") }}<br>
                                        {{ $t("message.road25") }}<br>
                                        {{ $t("message.road26") }}<br>
                                        {{ $t("message.road27") }}<br>
                                        {{ $t("message.road28") }}</p>
                                    </div>
                                </div>
                            </div>
                        -->
                            <!--end col-->
                        <!--
                            </div>
                        -->
                        <!--end row-->
                    <!--
                        </div>
                    -->
                    <!--end timeline item-->

                    <!--<div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2">
                                <div class="card event event-description-left rounded shadow border-0 overflow-hidden float-left">
                                    <img src="images/gopax_black.png" class="img-fluid" alt="">
                                    <div class="card-body">
                                        <h5 class="mb-0 text-capitalize">{{ $t("message.road30") }}</h5>
                                        <p class="mt-3 mb-0 text-muted">{{ $t("message.road31") }}<br>
                                        {{ $t("message.road32") }}<br>
                                        {{ $t("message.road33") }}</p>
                                    </div>
                                </div>
                            </div>
                        -->
                            <!--end col-->
                            <!--

                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">
                                <div class="duration duration-right rounded border p-2 px-4 position-relative shadow text-left">2020 4Q</div>
                            </div>
                        -->
                            <!--end col-->
                        <!--
                            </div>
                        -->
                        <!--end row-->
                    <!--
                        </div>
                    -->
                    <!--end timeline item-->




                </div>
                <!--end timeline page-->
                <!-- TIMELINE END -->
            </div>
            <!--end col-->
        </div>
        <!--end row-->



<br>
<br>

      <!--2024 roadmap-->

     <section id="2024roadmap" class="bg-half-200 d-table w-100" style="background: url('images/Pages/road-bg(2024)-240518.png') center center / cover no-repeat;">
         <div class="section-title text-md-left">
            <p halign="center">
                     <h4 class="mb-4">{{ $t("message.roaddt") }}</h4></p>
         </div>
        <div class="container mt-5">
            <div class="bg-trans" style="text-align: left;">
                 <div class="row">
                     <div class="col-sm"></div>
                     <div class="col-sm"></div>
                     <div class="col-sm"></div>
                     <div class="col-sm">
                         <p class="mt-3 mb-0 text-muted"><h6>
                             {{ $t("message.roadd41") }}<br>
                             {{ $t("message.roadd42") }}<br>
                             {{ $t("message.roadd43") }}</h6></p>
                     </div>

                 </div>
                

                 <div class="row">
                     <div class="col-sm"></div>
                     <div class="col-sm"></div>
                     <div class="col-sm">
                         <p class="mt-3 mb-0 text-muted"><h6>
                             {{ $t("message.roadd31") }}<br>
                             {{ $t("message.roadd32") }}</h6></p>
                     </div>
                     <div class="col-sm"></div>
                 </div>


                 <div class="row">
                    <div class="col-sm"></div>
                 <div class="col-sm">
                     <p class="mt-3 mb-0 text-muted"><h6>
                         {{ $t("message.roadd21") }}<br>
                         {{ $t("message.roadd22") }}<br>
                         {{ $t("message.roadd23") }}<br>
                         {{ $t("message.roadd24") }}</h6></p>
                 </div>
                    <div class="col-sm"></div>
                    <div class="col-sm"></div>
                 </div>


                 <div class="row">
                 <div class="col-sm">
                     <p class="mt-3 mb-0 text-muted"><h6>
                         {{ $t("message.roadd11") }}<br>
                         {{ $t("message.roadd12") }}<br>
                         {{ $t("message.roadd13") }}</h6></p>
                 </div>
                    <div class="col-sm"></div>
                    <div class="col-sm"></div>
                    <div class="col-sm"></div>
                 </div>

            </div>
        </div>
     </section>



        

     <!--2024 roadmap original-->
     <!--

     <section id="2024roadmap" class="bg-half-200 d-table w-100" style="background: url('images/Pages/road-bg(2024)-240518.png') center center / cover no-repeat;">
        
        <div class="container mt-5">
            <div class="bg-trans" style="text-align: right;">
                 <div class="row">
                     <div class="col-sm"></div>
                     
                     
                     <div class="col-sm">
                         <div class="row">
                             <div class="col-sm">
                             <p class="mt-3 mb-0 text-muted"><h6>
                                 {{ $t("message.roadd41") }}<br>
                                 {{ $t("message.roadd42") }}<br>
                                 {{ $t("message.roadd43") }}</h6></p>
                             </div>
                             <div class="col-sm">
                                 
                             <img src="images/Pages/sharehouse.jpg" class="img-fluid" alt="">
                                 
                             </div>
                         </div>
                     </div>
                 </div>
                

                 <div class="row">
                     <div class="col-sm"></div>
                     <div class="col-sm"></div>
                     <div class="col-sm">
                         <p class="mt-3 mb-0 text-muted">
                             {{ $t("message.roadd31") }}<br>
                             {{ $t("message.roadd32") }}</p>
                     </div>
                     <div class="col-sm"></div>
                 </div>


                 <div class="row">
                    <div class="col-sm"></div>
                 <div class="col-sm">
                     <p class="mt-3 mb-0 text-muted"><h6>
                         {{ $t("message.roadd21") }}<br>
                         {{ $t("message.roadd22") }}<br>
                         {{ $t("message.roadd23") }}<br>
                         {{ $t("message.roadd24") }}</h6></p>
                 </div>
                    <div class="col-sm"></div>
                    <div class="col-sm"></div>
                 </div>


                 <div class="row">
                 <div class="col-sm">
                     <p class="mt-3 mb-0 text-muted">
                         {{ $t("message.roadd11") }}<br>
                         {{ $t("message.roadd12") }}<br>
                         {{ $t("message.roadd13") }}</p>
                 </div>
                    <div class="col-sm"></div>
                    <div class="col-sm"></div>
                    <div class="col-sm"></div>
                 </div>

            </div>
        </div>
     </section>

    -->




     </div>




</section>








<section class="mt-5">
</section>

<!--end section-->

    <Footer />
    <!-- Footer End -->
    
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
